<template>
  <div class="main-view">
    <div class="content">
      <div class="text-content">
        <div class="text-title">审计助手Office插件</div>
        <div class="text-info">提高审计效率，减少熬夜加班</div>

        <div class="btn-group">
          <div class="download-box">
            <el-button @click="clickDownload">
              <el-icon><Files /></el-icon>工具下载
            </el-button>
          </div>
          <div class="menber-box">
            <el-button @click="clickMember">
              <el-icon><Present /></el-icon>开通会员
            </el-button>
          </div>
        </div>
        <div class="version-info">
          <div class="version-num" :title="applicationInfo ? applicationInfo.remarks : ''">
            当前版本号： <span>{{applicationInfo ? applicationInfo.version : ''}}</span>
          </div>
          <div class="version-time">
            <span>{{applicationInfo ? applicationInfo.updateTimeStr : ''}}</span>
          </div>
        </div>
        <div class="version-remarks" v-if="applicationInfo && this.applicationInfo.remarks" v-html="this.formattedText(this.applicationInfo.remarks)"></div>

      </div>
      <div class="execl-addin-img">
        <img src="../assets/home-image.png" class="header_logo header-logo" />
      </div>

    </div>
    <div class="introduce-list">
      <div class="dependent-content">
        <span>审计助手Office插件使用 VSTO 开发，可以安装于Windows平台（Windows 10、11），支持WPS、Office 2013、Office 2016、Office 2019、Office 2021、Excel 365。<br/></span>
        <span>工具依赖于.Net4.7.2运行环境，如果安装插件时报错可以通过如下链接下载运行环境进行安装。<br/></span>
        <div class="dependent-btn">
          <span >.NET环境下载链接</span>
          <span style="font-weight: bold;">(安装报错时需要进行下载安装)：</span>
          <a href="https://pan.baidu.com/s/1PqLDz7Ae8DKlHJw4s-5iPg?pwd=sjxg" target="_blank">百度网盘</a>
          <a href="https://dotnet.microsoft.com/zh-cn/download/dotnet-framework/net472" target="_blank">微软官网</a>
        </div>
        <div class="dependent-btn">
          <span>VSTO环境下载链接</span>
          <span style="font-weight: bold;">(安装后没有工具的，需要先下载VSTO环境安装，再进行重新安装工具)：</span>
          <a href="https://pan.baidu.com/s/11bYK5BQfGH7eH9oNGkOBmg?pwd=sjxg" target="_blank">百度网盘</a>
          <a href="https://www.microsoft.com/zh-cn/download/details.aspx?id=105522" target="_blank">微软官网</a>
        </div>
        <div class="video-btn">
          <span >视频教程：</span>
          <a href="https://space.bilibili.com/414273341" target="_blank">审计小哥B站视频</a>
        </div>
      </div>
    </div>
    <div class="function-introduce">
      <div class="introduce-title">审计助手介绍</div>
      <div class="introduce-tab">
        <div class="introduce-tab-item execl" :class="{active: functionSelect == '1'}" @click="clickFunctionType('1')">Excel工具</div>
        <div class="introduce-tab-item word" :class="{active: functionSelect == '2'}" @click="clickFunctionType('2')">Word工具</div>
        <div class="introduce-tab-item question" :class="{active: functionSelect == '3'}" @click="clickFunctionType('3')">问题答疑</div>
      </div>
      <div class="introduce-content">
        <div class="left-content custom-overflow">
          <div v-for="(item,index) in functionList" :key="item.id" :class="{active: item.id == selectFunctionId}" class="function-item" @click="clickFunctionItem(item)" >
            <div class="item-title">{{item.group_name}}</div>
            <div class="item-content">{{item.group_info}}</div>
          </div>
        </div>
        <div class="right-content" v-if="this.selectFunctionItem && this.selectFunctionItem.detailList">
          <el-carousel  direction="horizontal" :autoplay="false" trigger="click" arrow="always" ref="imageCarousel">
            <el-carousel-item v-for="(item,index) in this.selectFunctionItem.detailList" :key="item.id">
              <div class="right-content-carousel">
                <div class="right-content-carousel-text" v-html="this.formattedText(item.detail_info)"></div>
                <el-image class="right-content-carousel-image" :src="item.fileUrl" fit="fill" loading="lazy"  />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

  </div>


  <!--会员窗口-->
  <el-dialog
    v-model="openMemberFlag"
    title="开通会员"
    class="member-dialog"
    :close-on-click-modal = "false"
  >
    <Member v-if="openMemberFlag"></Member>
  </el-dialog>
</template>

<script>
import Member from "./Member";
import { ElMessage } from 'element-plus'
import {getCookie} from "../net/cookies";
import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import defaultConfig from '@/config/defaultConfig'

export default {
  name: 'Main',
  components: {
    Member

  },
  data () {
    return {
      openMemberFlag: false,
      file_root: defaultConfig.server_file_root,
      applicationInfo: {},
      functionSelect: '3',
      functionList: [
      ],
      functionMap: {
        '1': [],
        '2': [],
        '3': [],
      },
      selectFunctionItem: {},
      selectFunctionId: 0,

    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      // 获取应用信息
      this.getApplicationInfo();
      // 获取问题答疑列表
      this.getQuestionList();
    },
    async getApplicationInfo(){
      // 获取应用信息
      let applicationResult = await nodeServer.applicationInfo_getOneByIsDefault();
      if(applicationResult && applicationResult.success && applicationResult.content){
        this.applicationInfo = applicationResult.content;
        // 更新时间 update_time
        if(this.applicationInfo.update_time){
          try{
            this.applicationInfo.updateTimeStr =  dateUtils.getDateString(new Date(this.applicationInfo.update_time));
          }catch (e) {

          }
        }else{
          this.applicationInfo.updateTimeStr = '';
        }
      }
    },
    async getQuestionList(){
      let res = await nodeServer.questionGroupInfo_getPage({
        pageNum: 1,
        pageSize: 100
      });
      if(res.success && res.content) {
        this.functionMap = {
          '1': [],
          '2': [],
          '3': [],
        };
        // 处理数据
        for (let row of res.content.rows) {
          this.functionMap[`${row.group_type}`].push(row);
        }
        this.functionList = this.functionMap[this.functionSelect];
        if(this.functionList && this.functionList.length > 0){
          this.selectFunctionId = this.functionList[0].id;
          // 切换内容
          await this.getQuestionDetailInfo(this.functionList[0].id);
        }
      }
    },
    clickMember(){
      // ElMessage('功能暂未开放.')
      // 判断用户是否登录
      let token = getCookie("token");
      if(!token){
        // 没有登录， 打开登录页面
        this.$emit('childEmit', 1);
        ElMessage('请先登录.')
        return;
      }else{
        this.openMemberFlag = true;
      }
    },
    clickDownload(){
      let baseUrl = defaultConfig.server_file_root;
      if(this.applicationInfo.filesInfoList && this.applicationInfo.filesInfoList.length > 0){
        let filesInfo = this.applicationInfo.filesInfoList[0];
        let filePath = filesInfo.file_path;
        if(filePath){
          window.open(
            baseUrl + filePath,
            '_self'
          );
        }
      } else {
        ElMessage.error('下载错误');
      }
    },
    clickFunctionType(functionSelect){
      this.functionSelect = functionSelect;
      this.functionList = this.functionMap[this.functionSelect];
      // 选中内容
      if(this.functionList && this.functionList.length > 0){
        this.clickFunctionItem(this.functionList[0]);
      }
    },
    clickFunctionItem(functionItem){
      this.selectFunctionId = functionItem.id;
      // 切换内容
      this.getQuestionDetailInfo(functionItem.id);
    },
    async getQuestionDetailInfo(questionId){
      let res = await nodeServer.questionGroupInfo_getDetailById({
        id: questionId,
      });
      if(res.success && res.content){
        this.selectFunctionItem = res.content;
        // 处理展示用的照片
        for(let detailItem of this.selectFunctionItem.detailList){
          if(detailItem.fileList && detailItem.fileList[0]){
            detailItem.fileUrl = defaultConfig.server_file_root + detailItem.fileList[0].file_path;
          }
        }
      }
    },
    formattedText(rawText) {
      return rawText.replace(/\n/g, "<br />");
    }
  }
}
</script>
<style lang="scss" scoped>
.main-view{
  .content{
    /*加载背景图 */
    background-image: url("../assets/home-backgroud.jpg");
    /* 背景图垂直、水平均居中 */
    background-position: center center;
    /* 背景图不平铺 */
    background-repeat: no-repeat;
    /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
    /*background-attachment: fixed;*/
    /* 让背景图基于容器大小伸缩 */
    background-size: cover;
    border-radius: 20px;

    box-shadow: 0 0 16px rgba(67, 67, 67, 0.9);  /* 水平偏移 垂直偏移 模糊半径 颜色 */


    width: 1400px;
    height: 600px;
    margin: 16px auto;
    position: relative;
    .text-content{
      position: absolute;
      left: 200px;
      top: 160px;
      .text-title{
        font-size: 40px;
        font-family: emoji;
        font-weight: bold;
        margin: 32px 0;
        color: rgba(255,255,255,0.8);
      }
      .text-info{
        font-size: 20px;
        margin: 16px 0;
        color: rgba(255,255,255,0.8);
        font-weight: bold;
      }
      .btn-group{
        margin: 32px 0;
        display: flex;
        .el-button{
          margin-right: 32px;
          height: 42px;
          width: 160px;
          background-color: rgba(255,255,255,0.8);
          font-weight: bold;
          .el-icon{
            margin-right: 8px;
            font-size: 18px;
          }
        }

        .el-button:hover{
          background-color: rgba(255,255,255, 1);
        }
      }

      .version-info{
        color: rgba(255,255,255,0.8);
        font-size: 14px;
        margin-right: 2rem;
        margin-top: 24px;
        cursor: pointer;
        display: flex;
        .version-num{
          margin-right: 16px;
          span{
            font-weight: 600;
          }
        }
        .version-time{
          font-size: 14px;
          border-left: solid 1px rgba(255,255,255,0.8) ;
          padding-left: 16px;
        }
      }
      .version-remarks{
        cursor: pointer;
        color: rgba(255,255,255,0.6);
        margin-top: 8px;
        font-size: 12px;
        margin-right: 2rem;
        line-height: 24px;
      }
    }
    .execl-addin-img{
      position: absolute;
      right: 180px;
      bottom: 140px;
      width: 440px;
      height: 320px;
      img{
        width: 440px;
        height: 320px;
      }
    }
  }
  .introduce-list{
    padding: 0 300px;
    font-size: 16px;
    line-height: 48px;
    margin: 32px 0;
    height: 280px;
    background: #fff;
    .dependent-content{
      a{
        margin: 0 16px;
      }
      .video-btn{
        margin: 12px 0;
        font-size: 24px;
        span{
          color: red;
          font-weight: bold;
          margin-right: 20px;
        }
      }
    }
  }
  .function-introduce{
    padding: 12px 300px;
    font-size: 16px;
    line-height: 32px;
    background: #fff;
    margin-bottom: 32px;
    .introduce-title{
      text-align: center;
      color: #23282d;
      font-size: 24px;
      margin: 0 32px;
      margin-bottom: 32px;
    }
    .introduce-tab{
      display: flex;
      width: 100%;
      height: 56px;
      margin: 24px 0;
      font-size: 20px;
      font-weight: bold;
      .introduce-tab-item{
        width: 160px;
        height: 48px;
        margin: 0 18px;
        border-radius: 4px;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
        opacity: 0.3;
        &:hover{
          opacity: 1;
        }
        &.active{
          opacity: 1;
        }
        &.word{
          background: #2b7dd3;
        }
        &.execl{
          background: #22a466;
        }
        &.question{
          background: #ff7875;
        }
      }
    }
    .introduce-content{
      display: flex;
      height: 560px;

      .left-content{
        width : 460px;
        margin-right: 20px;
        overflow-y: auto;
        cursor: pointer;
        .function-item{
          margin: 8px 16px;
          height: 100px;
          color: #595959;
          padding: 12px 20px;
          border-radius: 6px;
          &:hover{
            color: #262626;
            background: linear-gradient(to bottom, #f0f0f0, #fafafa);
          }
          &.active{
            color: #262626;
            background: linear-gradient(to bottom, #f0f0f0, #fafafa);
            box-shadow: 0 0 8px rgba(140, 140, 140, 0.8);  /* 水平偏移 垂直偏移 模糊半径 颜色 */
          }
          .item-title{
            font-size: 16px;
            font-weight: bold;
          }
          .item-content{
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
      .right-content{
        width : 840px;
        height: 560px;
        .right-content-carousel{
          .right-content-carousel-text{
            max-height: 120px;
            line-height: 28px;
            margin-bottom: 24px;
          }
        }
        .el-carousel{
          height: 560px;
          .el-carousel__item{
            height: 560px;
            display: flex;
            align-items: center; /* 垂直居中 */
          }
          .el-image{
            max-height: 460px;
            img{
              max-height: 460px;
            }
          }

          .el-carousel__button{
            background-color: var(--el-carousel-arrow-hover-background) !important;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
  .member-dialog{
    width: 600px !important;
    background: #f5f5f5;
    .el-dialog__body{
      padding: 12px 26px;
      border: #d9d9d9 solid 1px;
    }
  }
  .main-view{
    .right-content{
      .el-carousel{
        .el-carousel__button{
          background-color: rgba(31,45,61,0.23) !important;
        }
      }
    }
  }
</style>
